import OpenModal from './open_modal';
import {Fancybox} from "@fancyapps/ui";

class OpenModalAfterClose extends OpenModal {
    constructor(
        blockSelector,
        $button,
    ) {
        super(blockSelector, $button);
    }

    open() {
        const langPage = document.querySelector('html').getAttribute('lang');
        if (this.$block && this.$block.length) {
            // Fancybox.show([{ src: this.blockSelector, type: 'inline' }]);
            this.fancybox = new Fancybox([
                {
                    src: this.blockSelector,
                    type: 'inline',
                },
            ], {
                l10n: this.languages[langPage],
                dragToClose: false,
                autoFocus: false,
                // placeFocusBack: false,
                // trapFocus: false,
            });
            this.fancybox.on('closing', (fancybox, slide) => {
                if (typeof this.closeHandler === 'function') {
                    this.closeHandler();
                    if ($('.fancybox__container .review__popup_message').length) {
                        $( ".js--open_login" ).trigger( "click" );
                    }
                }
            });
        }
    }
}

export default OpenModalAfterClose;
