class HeaderCart {
    constructor($cartButton) {
        this.$cartButton = $cartButton;
    }

    init() {
        if (this.$cartButton.length && Number(this.$cartButton.find('.cart-products-count').text()) < 1) {
            this.$cartButton.addClass('isDisabled');
        } else {
            this.$cartButton.removeClass('isDisabled');
        }
    }
}

export default HeaderCart;
