import en from '@fancyapps/ui/src/Fancybox/l10n/en';
import { Fancybox } from '@fancyapps/ui';
import uk from '../../_helpers/js/fancybox_languages/uk';
import ContactForm from '../contact_form/contact_form';

class ListAvailability {
    constructor(blockSelector) {
        this.languages = { en, uk };
        this.blockSelector = blockSelector;
        this.$block = $(blockSelector);
        this.langPage = document.querySelector('html').getAttribute('lang');
    }

    init() {
        const {
            blockSelector,
            $block,
            langPage,
            languages,
        } = this;

        $(document).on('click', '.js--notify_availability_form__link', function () {
            if ($block && $block.length) {
                this.fancybox = new Fancybox([
                    {
                        src: blockSelector,
                        type: 'inline',
                    },
                ], {
                    l10n: languages[langPage],
                    dragToClose: false,
                    autoFocus: false,
                });
            }

            const availabilityFormSearchResult = new ContactForm(blockSelector);
            availabilityFormSearchResult.addHandler();

            if ($(this).hasClass('js--notify_availability_form__link')) {
                const $parentWithProductId = $(this).closest('[data-id-product]');
                const productId = $parentWithProductId.data('id-product');

                const $productIdInput = availabilityFormSearchResult.$block.find('.js--product_id_form_availability');
                $productIdInput.val(productId);
            }
        });
    }
}

export default ListAvailability;
