class WaitingList {
	constructor() {
		//this.$productId = $productId;
	}

	removeFromList(elem) {
		const product = elem.parents(".product-miniature");
		const productId = product.attr("data-id-product");
		$.ajax({
			url: removeLink,
			type: "POST",
			cache: false,
			data: {
				ajax: true,
				productId,
			},
			success: function (result) {
				result = JSON.parse(result);
				if (result.success) {
					product.hide();
				}
			},
		});
	}
}

export default WaitingList;
