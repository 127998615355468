import "bootstrap";
// https://web.archive.org/web/20220927042317/http://dimox.name/jquery-form-styler/#dinamicheskoe-izmenenie-2-3-4
import "jquery-form-styler";
import "@fancyapps/fancybox/dist/jquery.fancybox";
import { Fancybox } from "@fancyapps/ui";
import RelatedPosts from "./components/related_posts/related_posts";
import FeaturedPosts from "./components/featured_posts/featured_posts";
import Map from "./components/map";
import ShowPassword from "./components/show_password/show_password";
import ContactForm from "./components/contact_form/contact_form";
import ContactFormModal from "./components/contact_form_modal";
import CheckoutRegister from "./components/checkout_register";
import ForgotPassword from "./components/forgot_password/forgot_password";
import OpenModalAfterClose from "./components/open_modal_after_close";
import MyAccount from "./components/my_account/my_account";
import WaitingList from "./components/my_account/waiting_list";
import MailingList from "./components/my_account/mailing";
import { cancelOrder } from "./components/my_account/cancel_order";
import OpenModal from "./components/open_modal";
import LoginRegisterModal from "./components/login_register_modal";
import LoadFile from "./components/load_file/load_file";
import Stars from "./components/stars/stars";
import ScrollUp from "./components/scroll_up/scroll_up";
import FancyBoxMessage from "./components/fancy_box_message";
import Header from "./components/header/header";
import Tooltip from "./components/tooltip";
import RegisterPage from "./components/register_page/register_page";
import en from "@fancyapps/ui/src/Fancybox/l10n/en";
import uk from "./_helpers/js/fancybox_languages/uk";
import SearchResult from "./components/search_result/search_result";
import ListAvailability from "./components/list_availability/list_availability";
import HeaderCart from "./components/header_cart";

$(document).ready(() => {
	// remove context menu
	// document.addEventListener('contextmenu', function(event) {
	// event.preventDefault();
	// });
	// substring user name in header
	const truncateText = (selector) => {
		const element = document.querySelector(selector);
		if (element) {
			let text = element.textContent.trim();
			if (text.length > 9) {
				text = `${text.substring(0, 9)}...`;
			}
			element.textContent = text;
		}
	};
	truncateText(".js--header_row__account_text");
	// jQuery plugin for styling HTML form elements
	$("select").styler();
	// add fade effect to submenu after menu item hover
	$(window)
		.resize(function () {
			if ($(window).width() >= 1200) {
				$(".jxmegamenu_item").hover(
					function () {
						let submenu = $(this).find(".jxmegamenu__submenu");
						setTimeout(function () {
							submenu.addClass("fade-in");
						}, 200);
					},
					function () {
						let submenu = $(this).find(".jxmegamenu__submenu");
						submenu.removeClass("fade-in");
						setTimeout(function () {
							submenu.removeClass("fade-in");
						}, 200);
					}
				);
			} else {
				$(".jxmegamenu_item").off("mouseenter mouseleave");
			}
		})
		.resize();
	// open login-popup after review popup (if not authorized)
	$(document).on(
		"click",
		"#form_review_popup .carousel__button.is-close",
		function () {
			if ($(".message.type-danger").length > 0) {
				$(".js--open_login").trigger("click");
			}
		}
	);
	$(document).on("click", ".fancybox__slide", function (event) {
		if (event.target === this) {
			if (
				$("#form_review_popup").length > 0 &&
				$(".message.type-danger").length > 0
			) {
				$(".js--open_login").trigger("click");
			}
		}
	});
	// productPage tubs
	$(".js--product-tabs__link").click(function (e) {
		e.preventDefault();
		const target = $(this).attr("href");
		const isExpanded = $(this).attr("aria-expanded") === "true";
		$(this).attr("aria-expanded", !isExpanded);
		$(target).collapse(isExpanded ? "hide" : "show");
	});
	$(".js--show_password").each(function _() {
		const password = new ShowPassword($(this));
		password.addHandler();
	});
	$(".js--show_password").each(function _() {
		const password = new ShowPassword($(this));
		password.addHandler();
	});
	// products and brands sliders
	$(() => {
		$(".js__swiper_products").each(function _() {
			const $slider = $(this);
			const swiper = new Swiper($slider, {
				direction: "horizontal",
				slidesPerView: 6,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				observer: true,
				observeParents: true,
				spaceBetween: 24,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
					paginationClickable: true,
				},
				breakpoints: {
					1900: {
						spaceBetween: 5,
					},
					1199: {
						spaceBetween: 17,
						slidesPerView: 2,
						paginationClickable: true,
						pagination: {
							el: ".swiper-pagination",
							clickable: true,
						},
					},
					321: {
						slidesPerView: 1,
						spaceBetween: 0,
						paginationClickable: true,
						pagination: {
							el: ".swiper-pagination",
							clickable: true,
						},
					},
				},
			});
			if ($slider.find(".swiper-pagination-bullet").length < 2) {
				$slider.addClass("swiper_products__one_bullet");
			}
		});
		$(".not_available_list .products").each(function _() {
			const $slider = $(this);
			const swiper = new Swiper($slider, {
				direction: "horizontal",
				slidesPerView: 4,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				observer: true,
				observeParents: true,
				spaceBetween: 50,
				// pagination: {
				// 	el: ".swiper-pagination",
				// 	clickable: true,
				// 	paginationClickable: true,
				// },
				breakpoints: {
					1900: {
						spaceBetween: 50,
						slidesPerView: 4,
					},
					1199: {
						spaceBetween: 50,
						slidesPerView: 3,
						paginationClickable: true,
						pagination: {
							el: ".swiper-pagination",
							clickable: true,
						},
					},
					992: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
					321: {
						slidesPerView: 1,
						spaceBetween: 0,
						paginationClickable: true,
						pagination: {
							el: ".swiper-pagination",
							clickable: true,
						},
					},
				},
			});
			// if ($slider.find(".swiper-pagination-bullet").length < 2) {
			// 	$slider.addClass("swiper_products__one_bullet");
			// }
		});
		$(".js__product_page_swiper_products").each(function _() {
			const $slider = $(this);
			const swiper = new Swiper($slider, {
				direction: "horizontal",
				slidesPerView: 6,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				observer: true,
				observeParents: true,
				spaceBetween: 5,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
					paginationClickable: true,
					renderBullet(index, className) {
						return '<span class="' + className + '">' + (index + 1) + "</span>";
					},
				},
				breakpoints: {
					1199: {
						spaceBetween: 17,
						slidesPerView: 2,
						paginationClickable: true,
						pagination: {
							el: ".swiper-pagination",
							clickable: true,
						},
					},
					321: {
						slidesPerView: 1,
						spaceBetween: 0,
						paginationClickable: true,
						pagination: {
							el: ".swiper-pagination",
							clickable: true,
						},
					},
				},
			});
			if ($slider.find(".swiper-pagination-bullet").length < 2) {
				$slider.addClass("swiper_products__one_bullet");
			}
		});
		const swiperBrands = new Swiper(".js__swiper_brands", {
			direction: "horizontal",
			slidesPerView: 7,
			observer: true,
			observeParents: true,
			spaceBetween: 3,
			paginationClickable: true,
			slidesPerGroup: 7,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
				paginationClickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + (index + 1) + "</span>";
				},
			},
			breakpoints: {
				1900: {
					spaceBetween: 64,
					slidesPerView: 4,
					slidesPerGroup: 4,
				},
				1349: {
					spaceBetween: 6,
					slidesPerView: 4,
				},
				1199: {
					spaceBetween: 5,
					slidesPerView: 3,
					slidesPerGroup: 3,
				},
				376: {
					slidesPerView: 3,
					spaceBetween: 4,
				},
			},
		});
		$(document).on(
			"click",
			".js__swiper_brands .swiper-pagination span",
			function () {
				const index = $(this).index();
				const slidesPerView = swiperBrands.params.slidesPerView;
				const slidesToScroll = slidesPerView * index;
				swiperBrands.slideTo(slidesToScroll);
			}
		);
		const swiperBrandsCat = new Swiper($(".js__subcategories__brands"), {
			direction: "horizontal",
			slidesPerView: 12,
			observer: true,
			observeParents: true,
			spaceBetween: 0,
			paginationClickable: true,
			autoplay: {
				delay: 5000,
			},
			breakpoints: {
				1199: {
					spaceBetween: 18,
					slidesPerView: 4,
					autoplay: {
						delay: 5000,
					},
				},
				767: {
					spaceBetween: 18,
					slidesPerView: 2,
					autoplay: {
						delay: 5000,
					},
				},
			},
		});
	});
	// sort dropdown show
	$(document).on("click", ".custom-sort__value", () => {
		$(".custom-sort__dropdown-menu").addClass("show");
		// add filter marginTop (press sort)
		if ($(window).outerWidth() >= 1200) {
			const $height = $(".custom-sort__dropdown-menu").height();
			$("#af_form").css("margin-top", $height);
		}
	});
	// sort dropdown hide
	$(document).on("click", (e) => {
		if (!$(e.target).closest(".custom-sort__wrapper").length) {
			$(".custom-sort__dropdown-menu").removeClass("show");
			// remove filter marginTop
			if ($(window).outerWidth() >= 1200) {
				$("#af_form").css("margin-top", 0);
			}
		}
		e.stopPropagation();
	});
	// first tub default active
	if ($(".product-tabs").length) {
		$(".nav-tabs .nav-item:first-child").addClass("active");
		$(".tab-content .tab-pane:first-child").addClass("show in");
	}
	// product page tubs
	$(".nav-tabs li").click(function (e) {
		e.preventDefault();
		$(".nav-tabs li").removeClass("active");
		$(this).addClass("active");
		const tid = $(this).find("a").attr("href");
		$(".tab-pane").removeClass("show in");
		$(tid).addClass("show in");
	});
	// hide product thumbnails if item.length < 2
	if ($("body#product").length > 0) {
		const swiperSlidesCount = $(".product-images .swiper-slide").length;
		if (swiperSlidesCount < 2) {
			$(".product-images").addClass("product-images__hide_thumb");
		}
		if (swiperSlidesCount === 4) {
			$(document).on("click", ".swiper-button-next.out-container", () => {
				$(".swiper-button-next.out-container").addClass(
					"swiper-button-disabled"
				);
			});
		}
		function checkNextButtonDisabled() {
			const activeSlide = $(".product-images .swiper-slide.slide-active");
			const nextButton = $(".product-images .swiper-button-next");

			if (activeSlide.length === 0 || activeSlide.is(":last-child")) {
				nextButton.addClass("swiper-button-disabled");
			} else {
				nextButton.removeClass("swiper-button-disabled");
			}
		}
		checkNextButtonDisabled();
		$(".product-images .swiper-container").on(
			"transitionEnd",
			checkNextButtonDisabled
		);
	}
	// mobile menu open
	$(document).on("click", ".js__mobile_menu_toggle", () => {
		$("body").addClass("mobile_menu__open");
		$("body").removeClass("mobile_catalog_menu__open");
	});
	// mobile menu close
	$(document).on("click", ".js__mobile_menu_close", () => {
		$("body").removeClass("mobile_menu__open");
	});
	// mobile catalog menu open
	$(document).on("click", ".js__catalog_menu_toggle", () => {
		if ($("body.mobile_menu__open").length) {
			$("body").removeClass("mobile_menu__open");
		}
		$("body").addClass("mobile_catalog_menu__open");
	});
	// mobile catalog menu close
	$(document).on("click", ".js__jxmegamenu__close", () => {
		$("body").removeClass("mobile_catalog_menu__open");
	});
	// cart dropdown count minus
	$(document).on("click", ".js__btn-counter-minus", function () {
		const $input = $(this)
			.closest(".counter")
			.find(".js-cart-line-product-quantity");
		const $totalPriceElement = $(
			".js--shopping_cart_modal__product_price_value"
		);
		const $unitPriceElement = $(
			".js--shopping_cart_modal__product_price_value_one"
		);
		let count = parseInt($input.val()) - 1;
		count = count < 1 ? 1 : count;
		$input.val(count);
		$input.trigger("focusout");
		// Обновляем общую сумму
		const unitPrice = parseFloat(
			$unitPriceElement.text().replace(/\s/g, "").replace(",", ".")
		); // Заменяем пробелы и заменяем запятую на точку
		const totalPrice = unitPrice * count;
		$totalPriceElement.text(formatPrice(totalPrice));
		// Добавляем/удаляем класс disabled, чтобы предотвратить клик на кнопку минус, когда значение в инпуте равно 1
		if (count === 1) {
			$(this).addClass("disabled");
		} else {
			$(this).removeClass("disabled");
		}
	});
	// cart dropdown count plus
	$(document).on("click", ".js__btn-counter-plus", function () {
		const $input = $(this)
			.closest(".counter")
			.find(".js-cart-line-product-quantity");
		const $totalPriceElement = $(
			".js--shopping_cart_modal__product_price_value"
		);
		const $unitPriceElement = $(
			".js--shopping_cart_modal__product_price_value_one"
		);
        const quantityPerPackage = $input.attr('quantity-per-package');
		let count = parseInt($input.val()) + parseInt(quantityPerPackage);
		count =
			count > parseInt($input.data("max-count"))
				? parseInt($input.data("max-count"))
				: count;
		$input.val(parseInt(count));
		$input.trigger("focusout");
		// Обновляем общую сумму
		const unitPrice = parseFloat(
			$unitPriceElement.text().replace(/\s/g, "").replace(",", ".")
		); // Заменяем пробелы и заменяем запятую на точку
		const totalPrice = unitPrice * count;
		$totalPriceElement.text(formatPrice(totalPrice));
		// Удаляем класс disabled, чтобы активировать кнопку минус при увеличении значения в инпуте
		$(".js__btn-counter-minus").removeClass("disabled");
	});

    $(document).on('change', '.js-cart-line-product-quantity', function () {
        const $input = $(this);
        const $unitPriceElement = $(
            '.js--shopping_cart_modal__product_price_value_one'
        );
        const $totalPriceElement = $(
            '.js--shopping_cart_modal__product_price_value'
        );
        // Обновляем общую сумму
        const unitPrice = parseFloat($unitPriceElement.text().replace(/\s/g, "").replace(",", ".")); // Заменяем пробелы и заменяем запятую на точку

        const totalPrice = unitPrice * $input.val();
        $totalPriceElement.text(formatPrice(totalPrice));
    });
	// Function for formatting the total price of a product in a popup
	function formatPrice(price) {
		return (
			price
				.toFixed(2)
				.replace(".", ",")
				.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₴"
		); // Заменяем точку на запятую и добавляем обычные пробелы для разделителей групп разрядов
	}
	// dynamic change products count in mobile product card
	$("body").on("click", ".js__add-to-cart", function () {
		let productCardCount = $(this)
			.closest(".buttons-wrapper")
			.find(".js__product-miniature__cart-count");
		if (productCardCount.text().trim().length < 1) {
			productCardCount.text(1);
		} else {
			let productCardCountText = parseInt(productCardCount.text());
			productCardCountText++;
			productCardCount.text(productCardCountText);
		}
	});
	// wishlist page remove items
	$(document).on("click", ".jx-wishlist-button", function () {
		if ($("body#module-jxwishlist-wishlists").length) {
			$(this).closest(".js__favorite_products__item").remove();
		}
	});
	// add overlay after search input click
	$(document).on("click", ".js--jxsearchblock__input", function () {
		$("body").addClass("search_active");
		$(document).on("click.searchOutside", function (event) {
			if (!$(event.target).closest(".js--header_row__search").length) {
				$("body").removeClass("search_active");
				$(document).off("click.searchOutside");
			}
		});
		$(document).on("scroll.searchScroll", function () {
			if ($(this).scrollTop() > 0) {
				$("body").removeClass("search_active");
				$(document).off("scroll.searchScroll");
			}
		});
	});
	// hide dots if length < 2 in ProductPage
	if ($("body#product").length) {
		if ($(".product-images").find(".swiper-pagination-bullet").length < 2) {
			$(".product-images").addClass("swiper_products__one_bullet");
		}
		if ($(".product-images").find(".swiper-slide").length < 4) {
			$(".product-images").addClass("swiper_products__without_arrows");
		}
	}
	const relatedPosts = new RelatedPosts(
		$(".js--related_posts__slider"),
		$(".js--related_posts__wrapper")
	);
	relatedPosts.init();
	const featuredPosts = new FeaturedPosts(
		$(".js--featured_posts__slider"),
		$(".js--featured_posts__wrapper")
	);
	featuredPosts.init();
	const cartDropdown = new OpenModal(
		"#cart_dropdown",
		$(".js__cart_dropdown_show")
	);
	cartDropdown.addHandler();

	const minPricePopup = new OpenModal(
		"#js--alert-min-price",
		$(".js--min_price_popup_btn")
	);
	minPricePopup.addHandler();

	const wishlistModal = new OpenModal(
		"#form_wishlist_popup",
		$(".js__wishlist_modal_show"),
		() => {
			$(".form_wishlist .form_wishlist__dropdown .wishlist_dropdown").remove();
			$(".wishlist_dropdown").styler("destroy");
		}
	);
	wishlistModal.addHandler();

	const wishlistSelectModal = new OpenModal(
		"#form_wishlist_select_popup",
		$(".js__wishlist_select_modal_show")
	);
	wishlistSelectModal.addHandler();

	const wishlistCreateModal = new OpenModal(
		"#form_wishlist_create_popup",
		$(".js__wishlist_create_modal_show"),
		() => {
			$(".form_wishlist_create")
				.find(".error_title")
				.text("")
				.removeClass("show");
			$(".form_wishlist_create").find("input").removeClass("error");
		}
	);
	wishlistCreateModal.addHandler();

	const map = new Map(document.getElementById("map"));
	map.initMap();
	const partnersModal = new ContactFormModal(
		"#partners_form",
		$(".js--partners_link")
	);
	partnersModal.addHandler();
	const questionnaireModal = new ContactFormModal(
		"#questionnaire_form",
		$(".js--questionnaire__link")
	);
	questionnaireModal.addHandler();

	const guaranteesForm = new ContactFormModal(
		"#guarantees_form",
		$(".js--guarantees__link")
	);
	guaranteesForm.addHandler();

	const returnProductForm = new ContactFormModal(
		"#return_product_form",
		$(".js--return_product__link"),
		() => window.location.reload()
	);
	returnProductForm.addHandler();

	const certificatesForm = new ContactFormModal(
		"#certificates_form",
		$(".js--certificates__link"),
		() => window.location.reload()
	);
	certificatesForm.addHandler();

	const paymentsDataForm = new ContactFormModal(
		"#payments_data_form",
		$(".js--payments_data__link"),
		() => window.location.reload()
	);
	paymentsDataForm.addHandler();

	const checkoutRegister = new CheckoutRegister(
		$(".js--open_register"),
		$(".js--login-section"),
		$(".js--register-section")
	);
	checkoutRegister.addHandler();

	const contactForm = new ContactFormModal(
		"#contact_form",
		$(".js--contact_form__link")
	);
	contactForm.addHandler();

	const questionsForm = new ContactFormModal(
		"#questions_form",
		$(".js--questions_form")
	);
	questionsForm.addHandler();

	const notifyAvailabilityForm = new ContactFormModal(
		"#notify_availability_form",
		$(".js--notify_availability_form__link")
	);
	notifyAvailabilityForm.addHandler();

	const contactFormStatic = new ContactForm("#contacts_form_static");
	contactFormStatic.addHandler();

	const subscribeForm = new ContactForm("#subscribe_form");
	subscribeForm.addHandler();

	const loginRegister = new LoginRegisterModal();
	loginRegister.init();

	const reviewModal = new OpenModalAfterClose(
		"#form_review_popup",
		$(".lgcomment_button")
	);
	reviewModal.addHandler();

	const loadFile = new LoadFile();
	loadFile.init();

	const scrollUp = new ScrollUp($(".js--scroll_up"));
	scrollUp.init();

	const tooltip = new Tooltip($(".js--tooltip"), $(".js--tooltip_text"));
	tooltip.init();

	const registerPage = new RegisterPage(
		$(".js--register_page__form_button"),
		$(".js--register_page__form_checkbox")
	);
	registerPage.init();

	const searchResult = new SearchResult("#notify_availability_form");
	searchResult.init();

	// const listAvailability = new ListAvailability("#notify_availability_form");
	// listAvailability.init();

	const headerCart = new HeaderCart($(".js--cart_button"));
	headerCart.init();

	const forgotPassword = new ForgotPassword(
		"#forgot_password",
		$(".js--open_forgot_password"),
		$(".js--forgot_password_input"),
		$(".js--forgot_password_button_send"),
		$(".js--forgot_password_error"),
		$(".js--forgot_password_success"),
		$(".js--forgot_content")
	);
	forgotPassword.addHandler();
	global.fancyBoxMessage = new FancyBoxMessage();

	const header = new Header($(".js--floating_header"));
	header.addScrollHandlers();

	const stars = new Stars($(".js--stars"));
	stars.addHandler();

	if ($().mask) {
		const $phoneInputs = $("input.js--phone_mask");
		if ($phoneInputs) {
			$phoneInputs.mask("+38 (000) 000-00-00");
		}
	} else {
		console.log("jQuery-Mask-Plugin not found");
	}

	const myAccount = new MyAccount();

	$(".account_blocks .edit").on("click", function () {
		const form = $(this).parents("form");
		myAccount.openForm(form);
	});

	$("body").on("click", ".account_blocks .edit_button", function () {
		const form = $(this).parents("form");
		const dataId = $(this).attr("data-id");
		myAccount.getData(form, dataId);
	});

	$(".account_blocks .close_edit").on("click", function () {
		const form = $(this).parents("form");
		myAccount.closeForm(form);
	});

	$(".account_blocks .form").on("submit", function (e) {
		myAccount.saveForm($(this));
		e.preventDefault();
	});

	myAccount.initSortDropdown();

	const waitingList = new WaitingList();

	$(".waiting_list .remove_from_list").on("click", function () {
		waitingList.removeFromList($(this));
	});

	const mailingList = new MailingList();

	$(".mailing input").on("change", function () {
		mailingList.editMailingList($(this));
	});

	$(".js__confirm_modal_show").on("click", function () {
		const order_id = $(this).attr("data-order-id");

		$("#confirm_modal input[name='order_id']").val(order_id);
	});

	$("body").on("click", ".cancel_order", function () {
		const order_id = $("#confirm_modal input[name='order_id']").val();
		cancelOrder(order_id);
	});

	$(".animated_list .animated_item").each(function (index, item) {
		setTimeout(() => {
			$(item).addClass("animate");
		}, 175 * index);
	});
});
