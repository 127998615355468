import OpenModal from "../open_modal";
import { editPhoneSendCode, editPhoneVerifySms } from "./confirm_code";
import { FilterOrders } from "./my_orders";

const confirmCodeModal = new OpenModal("#confirm_code_modal", "");

class MyAccount {
	constructor() {}

	openForm(form, data_id = 0) {
		const content = $(form).find(".data_content");
		const form_content = $(form).find(".form_content");
		$(form).find('input[name="data_id"]').val(data_id);
		content.addClass("hide");
		setTimeout(() => {
			form_content.css("display", "flex");
			content.css("display", "none");
		}, 300);
		setTimeout(() => {
			form_content.addClass("show");
		}, 350);
	}

	closeForm(form) {
		const content = $(form).find(".data_content");
		const form_content = $(form).find(".form_content");
		$(form).find(".error_title").text("").removeClass("show");
		$(form).find("input").removeClass("error");
		if ($(form).attr("need-clear")) {
			$(form).find("input").val("");
		}
		$(form).find('input[name="data_id"]').val(0);

		form_content.removeClass("show");
		content.removeClass("hide");

		setTimeout(() => {
			form_content.css("display", "none");
			content.css("display", "flex");
		}, 300);
	}

	getData(form, data_id) {
		const type = form.attr("data-type");

		const formData = new FormData();
		formData.append("type", type);
		formData.append("mode", "get_data");
		formData.append("data_id", data_id);
		formData.append("ajax", "1");

		$.ajax({
			url: ajaxLink,
			data: formData,
			processData: false,
			contentType: false,
			type: "POST",
			success: (response) => {
				response = JSON.parse(response);
				Object.keys(response.data).map((key) => {
					$(form)
						.find("input[name='" + key + "']")
						.val(response.data[key]);
				});
				this.openForm(form, data_id);
			},
			error: function () {},
		});
	}

	saveForm(form) {
		const formData = new FormData(form[0]);
		const type = form.attr("data-type");
		formData.append("type", type);
		formData.append("ajax", "1");
		$(form).find(".error_title").text("").removeClass("show");
		$(form).find("input").removeClass("error");
		$.ajax({
			url: ajaxLink,
			data: formData,
			processData: false,
			contentType: false,
			type: "POST",
			success: (response) => {
				response = JSON.parse(response);
				if (response.status) {
					if (type == "user_data") {
						Object.keys(response).map((key) => {
							$(form)
								.find(".data_content ." + key)
								.text(response[key]);
						});
					}
					if (type == "address") {
						if (response.mode == "add") {
							let addressTemplate = $(".address_template").html();
							addressTemplate = addressTemplate.replace(
								new RegExp("%address%", "g"),
								response.address
							);
							addressTemplate = addressTemplate.replace(
								new RegExp("%id%", "g"),
								response.data_id
							);
							$("form[data-type='address'] .user_data_full").prepend(
								addressTemplate
							);
						}
					}
					if (type == "recipients") {
						if (response.mode == "add") {
							let recipientTemplate = $(".recipient_template").html();
							recipientTemplate = recipientTemplate.replace(
								new RegExp("%name%", "g"),
								response.name
							);
							recipientTemplate = recipientTemplate.replace(
								new RegExp("%id%", "g"),
								response.data_id
							);
							recipientTemplate = recipientTemplate.replace(
								new RegExp("%phone%", "g"),
								response.phone
							);
							$(
								"form[data-type='recipients'] .data_content .user_data"
							).prepend(recipientTemplate);
						}
					}
					if (type == "payments_data") {
						if (response.mode == "add") {
							let paymentDataTemplate = $(".payment_data_template").html();
							paymentDataTemplate = paymentDataTemplate.replace(
								new RegExp("%company%", "g"),
								response.company
							);
							paymentDataTemplate = paymentDataTemplate.replace(
								new RegExp("%edrpou%", "g"),
								response.edrpou
							);
							paymentDataTemplate = paymentDataTemplate.replace(
								new RegExp("%edrpou%", "g"),
								response.edrpou
							);
							paymentDataTemplate = paymentDataTemplate.replace(
								new RegExp("%invoice%", "g"),
								response.invoice
							);
							$(
								"form[data-type='payments_data'] .data_content .user_data"
							).prepend(paymentDataTemplate);
						}
					}
					if (type == "user_contacts") {
						if (response.mode == "validate") {
							if (response.send_sms) {
								const phone = $("input[name='phone_login']").val();
								editPhoneSendCode("", phone);
								//$(".js--confirm_code__code_send").show();
								confirmCodeModal.open();
								return;
							} else {
								form.find("input[name='mode']").val("update");
								const myAccount = new MyAccount();
								myAccount.saveForm(form);
								form.find("input[name='mode']").val("validate");
							}
						}
						if (response.mode == "update") {
							Object.keys(response).map((key) => {
								$(form)
									.find(".data_content ." + key)
									.text(response[key]);
							});
						}
					}
					if (type == "password") {
						window.location.reload();
					}
					if (response.mode == "update") {
						Object.keys(response).map((key) => {
							$(form)
								.find(".edit_data[data-id='" + response.data_id + "'] ." + key)
								.text(response[key]);
						});
					}
					this.closeForm(form);
				}
				if (!response.status) {
					if (response.errors) {
						Object.keys(response.errors).map((key) => {
							$(form)
								.find("." + key + " .error_title")
								.text(response.errors[key])
								.addClass("show");
							$(form)
								.find("input[name=" + key + "]")
								.addClass("error");
						});
					}
				}
			},
			error: function () {},
		});
	}

	initSortDropdown() {
		$(".sort li").on("click", function () {
			$(".sort").removeClass("opened");
		});
		$(".sort")
			.on("click", function () {
				$(".sort").not(this).removeClass("opened");
				$(this).toggleClass("opened");
			})
			.find("li")
			.click(function (e) {
				e.stopPropagation();
			});
		$(document).click(function (event) {
			var $target = $(event.target);
			if (!$target.closest(".sort").length) {
				$(".sort").removeClass("opened");
			}
		});
	}

	updateUserData() {}
}

// $(".send_code").on("click", () => {
// });

$(".edit_phone_login_forms .js-verifismscode-click").on("click", () => {
	editPhoneVerifySms().done((response) => {
		if (response.Verification && !response.hasError) {
			fancyBoxMessage.showMessage("Дякуємо, ваш номер змінено");
			const form = $(".form[data-type='user_contacts']");
			form.find("input[name='mode']").val("update");
			const myAccount = new MyAccount();
			myAccount.saveForm(form);
			form.find("input[name='mode']").val("validate");
		}
	});
});

let previousValue = "";

const maskDate = (value, previousValue) => {
	if (!value) return value;
	let output = "";
	if (!previousValue || value.length > previousValue.length) {
		value.replace(
			/^\D*(\d{0,2})\D*(\d{0,2})\D*(\d{0,4})/,
			function (match, g1, g2, g3, g4) {
				if (g1.length) {
					output += g1;
					if (g1.length == 2) {
						output += "-";
						if (g2.length) {
							output += g2;
							if (g2.length == 2) {
								output += "-";
								if (g3.length) {
									output += g3;
								}
							}
						}
					}
				}
			}
		);
	} else {
		if (value[value.length - 1] == "-") {
			value = value.slice(0, value.length - 1);
		}
		output = value;
	}
	return output;
};
if (document.querySelector('input[name="birthday"]')) {
	document
		.querySelector('input[name="birthday"]')
		.addEventListener("input", (e) => {
			const value = e.target.value;

			const maskedValue = maskDate(value, previousValue);
			previousValue = maskedValue;
			e.target.value = maskedValue;
		});
}

export default MyAccount;
