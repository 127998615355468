import SwitcherBlocks from './switcher_blocks';
import FormModal from './form_modal';
import Form from './forms/form';

class LoginRegisterModal {
    constructor() {
        const loginSelector = '.js--login_content';
        const registerSelector = '.js--create_account_content';
        this.switcherBlocks = new SwitcherBlocks([
            { blockSelector: registerSelector, buttonSelector: '.js--open_create_account' },
            { blockSelector: loginSelector, buttonSelector: '.js--open_login_content' },
        ]);
        this.loginModal = new FormModal('#login_form', $('.js--open_login'), this.dispatcherClose.bind(this));
        // eslint-disable-next-line prefer-destructuring
        this.$registerForm = $(registerSelector).find('form')[0];
        this.registerForm = new Form(this.$registerForm, 'js--success');
    }

    init() {
        this.switcherBlocks.addHandler();
        this.loginModal.addHandler();
        this.loginModal.initValidate([
            { name: 'phone_login', typeValidate: ['required', 'phoneLength'], paramsValidate: [19] },
            { name: 'password', typeValidate: 'required' },
        ]);
        this.registerForm.addHandler(true);
        this.registerForm.initValidate([
            { name: 'phone_login', typeValidate: ['required', 'phoneLength'], paramsValidate: [19] },
            { name: 'password', typeValidate: 'required' },
            { name: 'repeat_password', typeValidate: ['required', 'equal'], paramsValidate: ['password'] },
        ]);
    }

    dispatcherClose() {
        if (this.$registerForm instanceof HTMLElement) {
            this.registerForm.clearAllElements();
        }
    }
}

export default LoginRegisterModal;
