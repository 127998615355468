class CheckoutRegister {
    constructor($buttonOpen, $loginContainer, $registerContainer) {
        this.$buttonOpen = $buttonOpen;
        this.$loginContainer = $loginContainer;
        this.$registerContainer = $registerContainer;
        this.$buttonSubmitOrder = $('#supercheckout_confirm_order');
    }

    addHandler() {
        if (this.$buttonOpen && this.$buttonOpen.length) {
            this.$buttonOpen.click((event) => {
                event.preventDefault();
                this.openRegister();
            });
        }
    }

    openRegister() {
        if (this.$loginContainer && this.$loginContainer.length
            && this.$registerContainer && this.$registerContainer.length) {
            this.$loginContainer.slideUp();
            this.$registerContainer.slideDown();
            $('#checkoutShippingAddress').data('noValidate', true);
            $('#supercheckout_confirm_order').removeClass('notLogged');
        }
    }
}

export default CheckoutRegister;
