const validators = {
    required(value) {
        return value.length > 0;
    },
    requiredCheck(checked) {
        return checked;
    },
    length(value, length = 0) {
        return value.length >= length;
    },
    phoneLength(value, length = 0) {
        return this.length(value, length);
    },
    equal(value, $target) {
        if ($target instanceof HTMLElement) {
            return value === $target.value;
        }
        return false;
    },
    numbers(value) {
        const reg = /^[0-9]*$/;
        return reg.test(value);
    },
    email(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            ) !== null;
    },
    selected(value) {
        return (value !== '');
    },
};

export default validators;
