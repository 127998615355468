import { Fancybox } from '@fancyapps/ui';
import en from '@fancyapps/ui/src/Fancybox/l10n/en';
import uk from '../_helpers/js/fancybox_languages/uk';

class FancyBoxMessage {
    constructor() {
        this.langPage = document.querySelector('html').getAttribute('lang');
    }

    showMessage(message, type = 'normal', closeHandler) {
        if (type !== 'danger') {
            Fancybox.close();
        }
        this.fancybox = new Fancybox([{ src: `<p class="message type-${type}">${message}</p>`, type: 'html' }], {
            l10n: this.langPage === 'en' ? en : uk,
            mainClass: 'fancybox__container--message',
        });
        this.fancybox.on('closing', () => {
            if (typeof closeHandler === 'function') {
                closeHandler();
            }
        });
    }
}

export default FancyBoxMessage;
