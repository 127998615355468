class Stars {
    constructor($block) {
        this.$block = $block;
        this.value = 0;
        if ($block && $block.length) {
            this.$stars = $block.find('.js--stars_item');
            this.$buttons = $block.find('.js--stars_button');
            this.$input = $block.find('.js--stars_input');
            if (this.$input && this.$input.length) {
                this.value = Number(this.$input.val());
            }
        }
    }

    addHandler() {
        if (this.$buttons && this.$buttons.length) {
            this.$buttons.hover((event) => {
                const value = Number($(event.currentTarget).data('value'));
                this.fillStarsRange(value);
            });
            this.$buttons.click((event) => {
                const value = Number($(event.currentTarget).data('value'));
                this.setValue(value);
            });
        }
        if (this.$block && this.$block.length) {
            this.fillStarsRange(this.value);
            this.$block.hover(null, () => {
                this.fillStarsRange(this.value);
            });
        }
    }

    fillStarsRange(index) {
        for (let i = 1; i <= index; i += 1) {
            if (i % 2 === 1) {
                this.fillStar((i + 1) / 2, 'half');
            } else {
                this.fillStar(i / 2, 'fill');
            }
        }
        const nextStar = index % 2 ? (index + 1) / 2 + 1 : index / 2 + 1;
        for (let i = nextStar; i <= 5; i += 1) {
            this.fillStar(i, 'empty');
        }
    }

    fillStar(index, type) {
        if (this.$stars && this.$stars.length) {
            const $item = $(this.$stars[index - 1]);
            $item.removeClass('fill').removeClass('half').removeClass('empty');
            $item.addClass(type);
        }
    }

    setValue(value) {
        if (this.$input && this.$input.length) {
            this.value = value;
            this.$input.val(value);
        }
    }
}

export default Stars;
