export default class Map {
    constructor(map) {
        this.map = map;
    }

    initMap() {
        if (this.map) {
            const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
            const coordinates = {
                lat: 50.43822454964994,
                lng: 30.615021091735112,
            };
            // eslint-disable-next-line no-undef
            const map = new google.maps.Map(this.map, {
                center: coordinates,
                zoom: 15,
                disableDefaultUI: true,
                gridlines: {
                    color: 'none',
                },
            });

            const imageMarker = {
                url: `/themes/EZE-251/assets/img/${isMobile() ? 'map-marker-small' : 'map-marker'}.png`,
            };
            // eslint-disable-next-line no-undef
            const marker = new google.maps.Marker({
                position: coordinates,
                map,
                icon: imageMarker,
            });

            marker.setMap(map);
        }
    }
}
