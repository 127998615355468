class RegisterPage {
    constructor($registerButton, $registerCheckbox) {
        this.$registerButton = $registerButton;
        this.$registerCheckbox = $registerCheckbox;
    }

    init() {
        if (this.$registerButton.length && this.$registerCheckbox.length) {
            this.$registerCheckbox.on('change', () => {
                // eslint-disable-next-line no-unused-expressions
                this.$registerCheckbox.attr('checked') === 'checked'
                    ? this.$registerButton.removeAttr('disabled')
                    : this.$registerButton.attr('disabled', 'disabled');
            });
        }
    }
}

export default RegisterPage;
