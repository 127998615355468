class MailingList {
	constructor() {}

	editMailingList(elem) {
		const name = elem.attr("name");
		const isChecked = elem.is(":checked");

		$.ajax({
			url: mailingLink,
			type: "POST",
			cache: false,
			data: {
				ajax: true,
				name,
				isChecked,
			},
			success: function (result) {},
		});
	}
}

export default MailingList;
