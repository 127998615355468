import OpenModal from './open_modal';
import Form from './forms/form';

class FormModal extends Form {
    constructor(blockSelector, $button, dispatcherClose) {
        const $form = $(blockSelector).find('form')[0];
        super($form, 'js--success');
        this.modal = new OpenModal(blockSelector, $button);
        this.dispatcherClose = dispatcherClose;
    }

    addHandler(withoutSend = true) {
        super.addHandler(withoutSend);
        this.modal.addHandler();
        this.modal.closeHandler = () => {
            this.resetForm();
            if (typeof this.dispatcherClose === 'function') {
                this.dispatcherClose();
            }
        };
    }

    initValidate(rules = []) {
        super.initValidate(rules);
    }

    resetForm() {
        if (this.$form instanceof HTMLElement) {
            this.clearAllElements();
        }
    }
}

export default FormModal;
