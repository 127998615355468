export default {
    CLOSE: 'Закрити',
    NEXT: 'Далі',
    PREV: 'Назад',
    MODAL: 'Ви можете закрити це вікно, натиснувши клавішу ESC',
    ERROR: 'Щось пішло не так. Будь ласка, повторіть спробу пізніше',
    IMAGE_ERROR: 'Зображення не знайдено',
    ELEMENT_NOT_FOUND: 'HTML елемент не знайдено',
    AJAX_NOT_FOUND: 'Помилка завантаження AJAX : Не знайдено',
    AJAX_FORBIDDEN: 'Помилка завантаження AJAX: Заборонено',
    IFRAME_ERROR: 'Помилка завантаження сторінки',
    TOGGLE_ZOOM: 'Переключити рівень масштабу',
    TOGGLE_THUMBS: 'Переключити ескіз',
    TOGGLE_SLIDESHOW: 'Переключити презентацію',
    TOGGLE_FULLSCREEN: 'Переключити режим повного екрану',
    DOWNLOAD: 'Завантажити',
};
