import OpenModal from '../open_modal';

class ForgotPassword extends OpenModal {
    constructor(
        blockSelector,
        $button,
        $input,
        $buttonSend,
        $error,
        $success,
        $content,
    ) {
        super(blockSelector, $button);
        this.$buttonSend = $buttonSend;
        this.$input = $input;
        this.$error = $error;
        this.$success = $success;
        this.$content = $content;
    }

    addHandler() {
        super.addHandler();
        if (this.$buttonSend && this.$buttonSend.length) {
            this.$buttonSend.click((event) => {
                event.preventDefault();
                if (this.validatePhone()) {
                    this.send();
                }
            });
        }
    }

    closeHandler() {
        super.closeHandler();
        this.resetModal();
    }

    open() {
        super.open();
        this.copyPhoneNumber();
    }

    copyPhoneNumber() {
        let $input;
        const inputSelector = 'input[name="phone_login"]';
        const $form = this.$button.closest('form');
        if ($form && $form.length) {
            $input = $form.find(inputSelector);
        } else {
            $input = $(inputSelector);
        }
        if ($input && $input.length && this.$input && this.$input.length) {
            this.$input.val($input.val());
        }
    }

    validatePhone() {
        this.hideError();
        this.hideSuccess();
        if (this.$input.val().length === 19) {
            return true;
        }
        if (this.$input.val().length === 0) {
            this.showError(this.$input.data('requireMsg'));
            return false;
        }
        this.showError(this.$input.data('errorMsg'));
        return false;
    }

    send() {
        /* global prestashop */
        const cThis = this;
        function cleanNonDigitChars(string) {
            if (!string || !string.length) {
                return '';
            }
            return string.replace(new RegExp('[^+0-9]+', 'g'), '');
        }
        function getURLwithTime(url) {
            return `${url + ((url.indexOf('?') < 0) ? '?' : '&')}rand=${new Date().getTime()}`;
        }
        const phoneLogin = cleanNonDigitChars(this.$input.val());
        $.ajax({
            type: 'POST',
            headers: {
                'cache-control': 'no-cache',
            },
            url: getURLwithTime('/index.php?fc=module&module=supercheckout&controller=supercheckout'),
            async: true,
            cache: false,
            dataType: 'json',
            data: {
                ajax: true, method: 'regeneratePassword', phoneLogin, token: prestashop.static_token,
            },
            beforeSend() {
                cThis.hideError();
            },
            success(jsonData) {
                if (jsonData.hasError) {
                    cThis.showError(jsonData.error);

                    if (jsonData.apiResponce) {
                        const apiResponce = `SMS ERROR: ${jsonData.apiResponce}`;
                        console.log(apiResponce);
                    }
                } else {
                    cThis.clearForm();
                    cThis.showSuccess();
                }
            },
            error(XMLHttpRequest, textStatus, errorThrown) {
                cThis.showError(
                    `Error.<br/>textStatus: '${textStatus}'<br/>errorThrown: '${
                        errorThrown}'<br/>responseText:<br/>${XMLHttpRequest.responseText}`,
                );
            },
        });
    }

    showError(message) {
        if (this.$error && this.$error.length) {
            this.$error.html(message);
            this.$error.slideDown();
        }
    }

    hideError() {
        if (this.$error && this.$error.length) {
            this.$error.slideUp();
            this.$error.html('');
        }
    }

    showSuccess() {
        if (this.$success && this.$success.length) {
            this.$success.slideDown();
        }
        if (this.$content && this.$content.length) {
            this.$content.slideUp();
        }
    }

    hideSuccess() {
        if (this.$success && this.$success.length) {
            this.$success.slideUp();
        }
        if (this.$content && this.$content.length) {
            this.$content.slideDown();
        }
    }

    clearForm() {
        if (this.$input && this.$input.length) {
            this.$input.val('');
        }
        this.hideError();
    }

    resetModal() {
        this.clearForm();
        this.hideSuccess();
    }
}

export default ForgotPassword;
