import { Fancybox } from "@fancyapps/ui";
import en from "@fancyapps/ui/src/Fancybox/l10n/en";
import uk from "../_helpers/js/fancybox_languages/uk";

class OpenModal {
	constructor(blockSelector, $button, closeHandler = false) {
		this.blockSelector = blockSelector;
		this.$block = $(blockSelector);
		this.closeHandler = closeHandler;
		this.$button = $button;
		this.languages = { en, uk };
	}

	addHandler() {
		if (
			this.$block &&
			this.$block.length &&
			this.$button &&
			this.$button.length
		) {
			this.$button.click((event) => {
				event.preventDefault();
				this.open();
			});
		}
	}

	open() {
		const langPage = document.querySelector("html").getAttribute("lang");
		if (this.$block && this.$block.length) {
			// Fancybox.show([{ src: this.blockSelector, type: 'inline' }]);
			this.fancybox = new Fancybox(
				[
					{
						src: this.blockSelector,
						type: "inline",
					},
				],
				{
					l10n: this.languages[langPage],
					dragToClose: false,
                    // enable autofocus for modal windows
					autoFocus: true,
					// placeFocusBack: false,
					// trapFocus: false,
				}
			);
			this.fancybox.on("closing", (fancybox, slide) => {
				if (typeof this.closeHandler === "function") {
					this.closeHandler();
				}
			});
		}
	}

	close() {
		this.fancybox.close();
	}

	closeHandler() {}
}

export default OpenModal;
