import OpenModal from "../open_modal";

const ConfirmModal = new OpenModal(
	"#confirm_modal",
	$(".js__confirm_modal_show")
);
ConfirmModal.addHandler();

export const cancelOrder = (id_order) => {
	$.ajax({
		url: ajax_link,
		type: "GET",
		dataType: "json",
		headers: { "cache-control": "no-cache" },
		cache: false,
		data: {
			ajax: true,
			type: "cancel_order",
			id_order,
		},
		success: function (response) {
			ConfirmModal.close();
			if (response.status) {
				if (response.order_id) {
					$(
						".my_orders__table_item[data-order-id='" +
							response.order_id +
							"'] .status"
					).text(response.statusText);
					return;
				}
				$(".top_block .status").html(`
                <svg class="status_icon" viewBox="0 0 27 25">
                    <use xlink:href="/themes/EZE-251/assets/img/symbol_sprite.svg?11#icon-error-status"></use>
                </svg>
                <span>${response.statusText}</span>
                `);
			}
		},
	});
};
