// import 'polyfill-object.fromentries';
import fromentries from 'fromentries';
import { Fancybox } from '@fancyapps/ui';
import en from '@fancyapps/ui/src/Fancybox/l10n/en';
import ValidateElement from './parts/validate_element';
import uk from '../../_helpers/js/fancybox_languages/uk';
import FancyBoxMessage from '../fancy_box_message';

class Form {
    constructor($form, successClass) {
        if ($form instanceof HTMLElement) {
            this.$form = $form;
            this.action = this.$form.getAttribute('action');
            this.method = this.$form.getAttribute('method');
            this.$buttonSubmit = this.$form.querySelector('button[type="submit"]');
            this.$success = this.$form.querySelector(`.${successClass}`);
            this.errorMessage = this.$form.dataset.errorMessage;
        }
        this.isValid = false;
        this.languages = { en, uk };
        this.validateElements = [];
        this.message = new FancyBoxMessage();
    }

    initValidate(rules = []) {
        if (this.$form instanceof HTMLElement) {
            const getParamsValidate = (element) => {
                if (element.typeValidate === 'equal' || (Array.isArray(element.typeValidate) && element.typeValidate.includes('equal'))) {
                    let targetName;
                    if (Array.isArray(element.paramsValidate) && element.paramsValidate.length) {
                        // eslint-disable-next-line prefer-destructuring
                        targetName = element.paramsValidate[0];
                    }
                    const $target = this.$form.querySelector(`input[name="${targetName}"]`);
                    if ($target instanceof HTMLElement) {
                        return [$target];
                    }
                    return false;
                }
                return element.paramsValidate && element.paramsValidate;
            };
            rules.forEach((element) => {
                const $input = this.$form.querySelector(`input[name="${element.name}"], textarea[name="${element.name}"]`);
                if ($input instanceof HTMLElement) {
                    const $element = $input.closest('.js--form_element');
                    if ($element instanceof HTMLElement) {
                        const validateElement = new ValidateElement(
                            $element,
                            element.typeValidate,
                            Form.validator,
                            getParamsValidate(element),
                        );
                        validateElement.addHandler();
                        this.validateElements.push(validateElement);
                    }
                }
            });
        }
    }

    validAllElements() {
        this.isValid = true;
        this.validateElements.forEach((element) => {
            element.validate();
            if (!element.isValid) {
                this.isValid = false;
            }
        });
    }

    clearAllElements() {
        this.validateElements.forEach((element) => {
            element.clear();
            Form.clearErrorElement(element.$input);
        });
    }

    addHandler(withoutSend = false) {
        if (this.$buttonSubmit instanceof HTMLElement && !withoutSend) {
            this.$buttonSubmit.addEventListener('click', (event) => {
                event.preventDefault();
                this.validAllElements();
                if (this.isValid) {
                    const recaptchaKey = document.querySelector('body').dataset.recaptcha_key;
                    /* global grecaptcha */
                    if (recaptchaKey) {
                        grecaptcha.ready(() => {
                            grecaptcha.execute(recaptchaKey, { action: 'submit' }).then((token) => {
                                this.send(token);
                            });
                        });
                    } else {
                        this.send();
                    }
                }
            });
        }
        if (this.$form instanceof HTMLElement) {
            this.$form.addEventListener('submit', (event) => {
                this.submitEvent(event, withoutSend);
            });
        }
    }

    submitEvent(event, withoutSend) {
        this.validAllElements();
        if (withoutSend && !this.isValid) {
            event.preventDefault();
            event.stopPropagation();
            if (this.errorMessage) {
                this.message.showMessage(this.errorMessage, 'danger');
            }
        } else if (!withoutSend && this.isValid) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    send(gRecaptchaResponse) {
        const form = new FormData(this.$form);
        if (gRecaptchaResponse) {
            form.append('gRecaptchaResponse', gRecaptchaResponse);
        }
        // const data = Object.fromEntries(form);
        const data = fromentries(form);
        const queryString = Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&');
        this.$buttonSubmit.setAttribute('disabled', 'disabled');
        fetch(this.action, {
            method: this.method,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', 'X-Requested-With': 'XMLHttpRequest' },
            body: queryString,
        })
            .then((response) => {
                if (response.status !== 200) {
                    return Promise.reject();
                }
                return response.json();
            })
            .then((rsp) => {
                if (rsp.status === 'success') {
                    this.showSuccess();
                    this.clearAllElements();
                } else if (rsp.status === 'error') {
                    if (rsp.errors) {
                        this.showErrorsByName(rsp.errors);
                    }
                }
                this.$buttonSubmit.removeAttribute('disabled');
            })
            .catch((err) => {
                console.log(err);
            });
    }

    static showErrorElement($input, text) {
        if ($input instanceof HTMLElement) {
            const $formElement = $input.closest('.js--form_element');
            if ($formElement instanceof HTMLElement) {
                $formElement.classList.add('error');
                const $message = $formElement.querySelector('.js--form_element_message');
                const $otherMessage = $formElement.querySelector('.help-block');
                if ($otherMessage instanceof HTMLElement) {
                    $otherMessage.remove();
                }
                if ($message instanceof HTMLElement) {
                    $message.innerHTML = text;
                    $message.style.display = 'block';
                }
            }
        }
    }

    static clearErrorElement($input) {
        if ($input instanceof HTMLElement) {
            const $formElement = $input.closest('.js--form_element');
            if ($formElement instanceof HTMLElement) {
                $formElement.classList.remove('error');
                const $message = $formElement.querySelector('.js--form_element_message');
                if ($message instanceof HTMLElement) {
                    $message.innerHTML = '';
                    $message.style.display = 'none';
                }
            }
        }
    }

    showErrorsByName(errors) {
        Object.entries(errors).forEach((error) => {
            const [key, value] = error;
            const $input = this.$form.querySelector(`input[name="${key}"], textarea[name="${key}"]`);
            this.showErrorElement($input, value);
        });
    }

    showSuccess() {
        const langPage = document.querySelector('html').getAttribute('lang');
        if (this.$success instanceof HTMLElement) {
            Fancybox.show(
                [
                    {
                        src: this.$success,
                        type: 'html',
                    },
                ],
                {
                    mainClass: 'form_success',
                    l10n: this.languages[langPage || 'uk'],
                },
            );
        }
    }

    static validator(element, event) {
        if (event.isValid === false) {
            Form.showErrorElement(element.$input, element.errorMessage);
        } else {
            Form.clearErrorElement(element.$input);
        }
    }
}

export default Form;
