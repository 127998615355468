class Tooltip {
    constructor($tooltip, $tooltipText) {
        this.$tooltip = $tooltip;
        this.$tooltipText = $tooltipText;
    }

    init() {
        const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);

        if (this.$tooltip.length && this.$tooltipText.length) {
            this.$tooltip.each(function () {
                if (isMobile()) {
                    $(this).on('click', function () {
                        $(this).next(this.$tooltipText).toggleClass('show');
                    });
                } else {
                    $(this).hover(function () {
                        $(this).next(this.$tooltipText).fadeIn(300);
                    }, function () {
                        $(this).next(this.$tooltipText).fadeOut(300);
                    });
                }
            });
        }
    }
}

export default Tooltip;
