class LoadFile {
    init() {
        $('#questionnaire_form__file').change(() => {
            if ($('#questionnaire_form__file').val()) {
                $('.js--questionnaire_label').addClass('uploaded');
            }
        });
        $('#questionnaire_form__file_second').change(() => {
            if ($('#questionnaire_form__file_second').val()) {
                $('.js--questionnaire_second_label').addClass('uploaded');
            }
        });

        $('.js--questionnaire_label .js--close_upload_file').click((e) => {
            e.preventDefault();
            $('#questionnaire_form__file').val('');
            $('.js--questionnaire_label').removeClass('uploaded');
        });

        $('.js--questionnaire_second_label .js--close_upload_file').click((e) => {
            e.preventDefault();
            $('#questionnaire_form__file_second').val('');
            $('.js--questionnaire_second_label').removeClass('uploaded');
        });
    }
}

export default LoadFile;
