class ShowPassword {
    constructor($inputBox) {
        this.$inputBox = $inputBox;
        this.$input = $inputBox.find('input');
        this.$button = $inputBox.find('.js--show_password_button');
        this.isHide = true;
    }

    addHandler() {
        if (this.$input && this.$input.length && this.$button && this.$button.length) {
            this.$button.click((event) => {
                event.preventDefault();
                this.toggle();
            });
        }
    }

    toggle() {
        if (this.isHide) {
            this.show();
        } else {
            this.hide();
        }
    }

    show() {
        this.isHide = false;
        this.$input.attr('type', 'text');
        this.$button.removeClass('type_hide');
    }

    hide() {
        this.isHide = true;
        this.$input.attr('type', 'password');
        this.$button.addClass('type_hide');
    }
}

export default ShowPassword;
