import OpenModal from "./open_modal";
import ContactForm from "./contact_form/contact_form";

class ContactFormModal extends OpenModal {
	constructor(blockSelector, $button, closeHandler = false) {
		super(blockSelector, $button);
		this.contactForm = new ContactForm(blockSelector, closeHandler);
	}

	addHandler() {
		super.addHandler();
		this.contactForm.addHandler();

		const self = this;

		this.$button.on("click", function (event) {
			event.preventDefault();

			// Check if the clicked button has the desired class
			if ($(this).hasClass("js--notify_availability_form__link")) {
				const $parentWithProductId = $(this).closest("[data-id-product]");
				const productId = $parentWithProductId.data("id-product");

				const $productIdInput = self.contactForm.$block.find(
					".js--product_id_form_availability"
				);
				$productIdInput.val(productId);
			}
		});
	}
}

export default ContactFormModal;
