var timerIntervalID;
var initTimeTimer = 60;
var timeTimer = initTimeTimer;

export const startVerifyTimer = () => {
	timeTimer = initTimeTimer;
	if (!timerIntervalID) {
		timerIntervalID = setInterval(updateVerifyTimer, 1000);
	}
};

const stopVerifyTimer = () => {
	clearInterval(timerIntervalID);
	timerIntervalID = null;
	$(".js-verifismscode-click").hide();
	$(".js--resend_code_button").show();
};

const updateVerifyTimer = () => {
	var timerElement = $(".js--confirm_code__code_approve_time");
	var minutes = Math.floor(timeTimer / 60);
	var seconds = timeTimer % 60;
	if (seconds < 10) {
		seconds = "0" + seconds;
	}

	if (minutes < 10) {
		minutes = "0" + minutes;
	}

	timerElement.text(`${minutes}:${seconds}`);

	if (timeTimer > 0) {
		timeTimer--;
	} else {
		stopVerifyTimer();
	}
};

let telephone = "";

export const editPhoneSendCode = (typeSend = "", phone) => {
	if (phone) {
		telephone = phone;
	}
	startVerifyTimer();
	$(".js-verifismscode-value").val("");
	$.ajax({
		type: "POST",
		async: !0,
		dataType: "json",
		url: prestashop.urls.base_url,
		headers: { "cache-control": "no-cache" },
		cache: !1,
		data: {
			fc: "module",
			phone: telephone,
			step: "otpravkasmseditaccount",
			module: "jxheaderaccount",
			controller: "smscoderegistration",
			ajax: true,
		},
		success: function (response) {
			if (typeSend === "resend") {
				startVerifyTimer();
				$(".js-verifismscode-click").show();
				$(".js--resend_code_button").hide();
			} else {
				if (response.hasError) {
					phoneSendInput.next(".input_box__error").show().text(response.errors);
				} else {
					$(".js--confirm_code__code_approve").show();
				}
			}
		},
	});
};

export const editPhoneVerifySms = () => {
	var verificationCodeInput = $(".js-verifismscode-value");
	var code = verificationCodeInput.val();

	return $.ajax({
		type: "POST",
		async: !0,
		dataType: "json",
		url: prestashop.urls.base_url,
		headers: { "cache-control": "no-cache" },
		cache: !1,
		data: {
			fc: "module",
			code: code,
			step: "verifismscode",
			module: "jxheaderaccount",
			controller: "smscoderegistration",
			ajax: true,
		},
		success: function (response) {
			if (response.hasError) {
				verificationCodeInput
					.next(".input_box__error")
					.show()
					.text(response.errors);
			} else {
				verificationCodeInput.next(".input_box__error").hide();
				$(".js--confirm_code__code_approve").hide();
			}
		},
	});
};

$(".resend_code_button_account").click(function (e) {
	e.preventDefault();
	if ($(this).attr("data-resend")) {
		editPhoneSendCode("resend");
	}

	// if (!$(this).attr("data-resend")) {
	// 	if (!phoneSendInput.val()) {
	// 		phoneSendInput
	// 			.next(".input_box__error")
	// 			.text(phoneSendInput.attr("data-error-required-message"))
	// 			.show();
	// 	} else {
	// 		if (phoneSendInput.val().length !== 19) {
	// 			phoneSendInput
	// 				.next(".input_box__error")
	// 				.text(phoneSendInput.attr("data-error-correct-number"))
	// 				.show();
	// 		} else {
	// 			phoneSendCode();
	// 			phoneSendInput.next(".input_box__error").hide();
	// 		}
	// 	}
	// }
});
