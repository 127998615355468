import validators from './validators';

class ValidateElement {
    constructor($element, typeValidate, dispatcher, paramsValidate = []) {
        this.$element = $element;
        if (this.$element instanceof HTMLElement) {
            this.$input = this.$element.querySelector('input, textarea');
            if (this.$input instanceof HTMLElement) {
                this.errorMessage = this.$input.dataset.errorMessage ? this.$input.dataset.errorMessage : 'Error';
            }
        }
        this.dispatcher = dispatcher;
        this.typeValidate = typeValidate;
        this.isValid = false;
        this.paramsValidate = paramsValidate;
    }

    addHandler() {
        if (this.$input instanceof HTMLElement) {
            this.$input.addEventListener('change', this.validate.bind(this));
            this.$input.addEventListener('blur', this.validate.bind(this));
            this.$input.addEventListener('keydown', () => {
                this.dispatcher(this, { isValid: true });
            });
        }
    }

    validate() {
        let { value } = this.$input;
        if (this.$input.getAttribute('type') === 'checkbox') {
            value = this.$input.checked;
        }
        if (Array.isArray(this.typeValidate)) {
            this.isValid = true;
            this.typeValidate.forEach((rule) => {
                if (this.isValid && Object.prototype.hasOwnProperty.call(validators, rule)) {
                    const isValidPart = validators[rule](value, ...this.paramsValidate);
                    if (isValidPart === false) {
                        this.isValid = false;
                        const ruleMessage = this.$input.dataset[`errorMessage${rule.charAt(0).toUpperCase() + rule.slice(1)}`];
                        if (ruleMessage) {
                            this.errorMessage = ruleMessage;
                        }
                    }
                }
            });
        } else if (Object.prototype.hasOwnProperty.call(validators, this.typeValidate)) {
            this.isValid = validators[this.typeValidate](value, ...this.paramsValidate);
        } else {
            this.isValid = true;
        }
        this.dispatcher(this, { isValid: this.isValid });
    }

    clear() {
        this.isValid = false;
        if (this.$input.getAttribute('type') === 'checkbox') {
            this.$input.checked = false;
        } else {
            this.$input.value = '';
        }
    }
}

export default ValidateElement;
