class Header {
    constructor($header) {
        this.$header = $header;
        this.$mini = $header.find('.js--header_for_mini');
        this.topForHide = 0;
        this.$body = $('body');
        if (this.$mini && this.$mini.length === 1) {
            this.topForHide = this.$mini.position().top;
            this.$body.data('heightHeader', this.$mini.outerHeight());
            this.$body.css('--heightHeader', `${this.$mini.outerHeight()}px`);
        }
        this.prevScroll = $(document).scrollTop();
    }

    addScrollHandlers() {
        if (this.$header && this.$header.length === 1) {
            $(window).scroll(() => {
                this.calculateDirect($(document).scrollTop());
            });
            $(window).resize(() => {
                this.refreshHeight();
            });
        }
    }

    calculateDirect(scroll) {
        if ((scroll - this.prevScroll) > 0) {
            this.minimize();
        } else {
            this.normalize();
        }
        this.prevScroll = scroll;
    }

    refreshHeight() {
        if (this.$mini && this.$mini.length === 1) {
            this.topForHide = this.$mini.position().top;
            this.$body.data('heightHeader', this.$mini.outerHeight());
            this.$body.css('--heightHeader', `${this.$mini.outerHeight()}px`);
        }
    }

    checkElements() {
        return (this.$header && this.$header.length === 1 && this.$mini && this.$mini.length === 1);
    }

    minimize() {
        if (this.checkElements()) {
            this.$header.css('top', `-${this.topForHide}px`);
        }
    }

    normalize() {
        if (this.checkElements()) {
            this.$header.css('top', '-1px');
        }
    }
}

export default Header;
