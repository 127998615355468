class SwitcherBlocks {
    constructor(blocks = [{ blockSelector: '.switcher_block_1', buttonSelector: '.open_switcher_block_1' }], type = 'display') {
        this.blocks = blocks.map((block) => ({
            selector: block.blockSelector,
            $block: $(block.blockSelector),
            $button: $(block.buttonSelector),
        }));
        this.type = type;
    }

    addHandler() {
        this.blocks.forEach((block) => {
            if (block.$block.length && block.$button.length) {
                block.$button.click(() => {
                    this.openBlock(block);
                });
            }
        });
    }

    openBlock(block) {
        if (block.$block && block.$block.length) {
            switch (this.type) {
                case 'display':
                    this.closeOtherBlocks(block);
                    block.$block.show();
                    break;
                default:
                    break;
            }
        }
    }

    closeBlock(block) {
        if (block.$block && block.$block.length) {
            switch (this.type) {
                case 'display':
                    block.$block.hide();
                    break;
                default:
                    break;
            }
        }
    }

    closeOtherBlocks(excludeBlock) {
        this.blocks.forEach((block) => {
            if (excludeBlock !== block) {
                this.closeBlock(block);
            }
        });
    }
}

export default SwitcherBlocks;
