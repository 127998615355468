import ContactFormModal from "../contact_form_modal";

const commercialForm = new ContactFormModal(
	"#commercial_form",
	$(".js--commercial__link")
);

commercialForm.addHandler();
const filter = {
	name: "",
	date_from: "",
	date_to: "",
	status: "",
	price_from: "",
	price_to: "",
	payment: "",
	address: "",
};

export const FilterOrders = () => {
	Object.keys(filter).map((key) => {
		if (filter[key]) {
			$(".clear_filter").addClass("show");
			return;
		}
	});
	$.ajax({
		url: ajax_link,
		type: "GET",
		dataType: "json",
		headers: { "cache-control": "no-cache" },
		cache: false,
		data: {
			ajax: true,
			type: "filter_orders",
			filter,
		},
		success: function (response) {
			if (response.status) {
				$(".my_orders__table_items").html(response.html);
				setTimeout(() => {
					const commercialForm = new ContactFormModal(
						"#commercial_form",
						$(".js--commercial__link")
					);
					commercialForm.addHandler();
				}, 600);
			}
			if (!response.status) {
				$(".my_orders__table_items").html("");
				fancyBoxMessage.showMessage(
					"На жаль, за вашим запитом нічого не знайдено",
					"success"
				);
			}
		},
	});
};

$(".my_orders .sort_dropdown li").on("click", function () {
	const type = $(this).parents(".sort_dropdown").attr("data-type");

	const title = $(this).find("span").text();

	filter[type] = $(this).attr("data-value");

	$(this).parents(".sort").find(".sort_title").text(title);

	FilterOrders();
});

let time;

$(".my_orders .account_header .search input").on("input paste", function () {
	filter.name = $(this).val();
	if (time !== "undefined") {
		clearTimeout(time);
	}

	time = setTimeout(function () {
		FilterOrders();
	}, 1000);
});

$(".clear_filter").on("click", function () {
	Object.keys(filter).map((key) => {
		filter[key] = "";
	});
	$(".my_orders .account_header .search input").val("");
	$(".my_orders .filter input").val("");
	FilterOrders();

	if ($(window).width() > 768) {
		$(".sort .sort_title").text(filter_titles.pc);
	} else {
		$(".sort .sort_dropdown").each(function (index, item) {
			const attr = $(item).attr("data-type");
			if (typeof filter_titles[attr] !== "undefined") {
				$(item).parents(".sort").find(".sort_title").text(filter_titles[attr]);
			}
		});
	}
	$(".clear_filter").removeClass("show");
});

$(".sort.filter input").on("input paste", function () {
	const inputs = $(this).parents(".sort").find("input");
	const sortDropdown = $(this).parents(".sort_dropdown");
	const type = $(this).parents(".sort_dropdown").attr("data-type");
	let validated = true;
	inputs.each(function (index, item) {
		if (!$(item).val() || $(item).val().length < $(item).attr("minlength")) {
			validated = false;
		}
	});

	if (time !== "undefined") {
		clearTimeout(time);
	}

	if (validated) {
		if (type == "date") {
			const day_from = sortDropdown.find('input[name="day_from"]').val();
			const month_from = sortDropdown.find('input[name="month_from"]').val();
			const year_from = sortDropdown.find('input[name="year_from"]').val();

			const day_to = sortDropdown.find('input[name="day_to"]').val();
			const month_to = sortDropdown.find('input[name="month_to"]').val();
			const year_to = sortDropdown.find('input[name="year_to"]').val();

			filter.date_from = day_from + "." + month_from + "." + year_from;

			filter.date_to = day_to + "." + month_to + "." + year_to;

			$(this)
				.parents(".sort")
				.find(".sort_title")
				.text(filter.date_from + " - " + filter.date_to);
		}
		if (type == "price") {
			const price_from = sortDropdown.find('input[name="price_from"]').val();

			const price_to = sortDropdown.find('input[name="price_to"]').val();

			filter.price_from = price_from;

			filter.price_to = price_to;

			$(this)
				.parents(".sort")
				.find(".sort_title")
				.text(filter.price_from + " - " + filter.price_to + " грн");
		}

		time = setTimeout(function () {
			FilterOrders();
		}, 1000);
	}
});
