class ScrollUp {
    constructor($button) {
        this.$button = $button;
    }

    init() {
        $(document).ready(() => {
            if (this.$button) {
                $(window).scroll(() => {
                    if ($(window).scrollTop() > 300) {
                        this.$button.show();
                    } else {
                        this.$button.hide();
                    }
                });
                this.$button.on('click', (e) => {
                    e.preventDefault();
                    $('html, body').animate({ scrollTop: 0 }, '300');
                });
            }
        });
    }
}

export default ScrollUp;
