import Swiper, { Pagination } from 'swiper';

export default class RelatedPosts {
    constructor(slider, wrapper) {
        this.slider = slider;
        this.wrapper = wrapper;
        this.swiper = null;
        this.options = {
            direction: 'horizontal',
            modules: [Pagination],
            autoHeight: true,
            observer: true,
            observeParents: true,
            paginationClickable: true,
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                1200: {
                    slidesPerView: 2,
                    spaceBetween: '4%',
                },
            },
        };
    }

    init() {
        this.initSlider();
        // $(window).resize(() => {
        //     this.initSlider();
        // });
    }

    setOptionsSlider() {
        return { ...this.options };
    }

    initSlider() {
        if (this.isNeedSwiper()) {
            this.swiper = new Swiper(this.slider[0], this.setOptionsSlider());
        }
        if (!this.isNeedSwiper() && this.swiper) {
            this.swiper.destroy(true, true);
        }
    }

    isNeedSwiper() {
        return ($(window).width() < 1200 && this.checkSlides(1))
            || (($(window).width() >= 1200) && this.checkSlides(2));
    }

    checkSlides(countSlides) {
        return this.wrapper.children().length > countSlides;
    }
}
