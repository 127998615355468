import FancyBoxMessage from "../fancy_box_message";

class ContactForm {
	constructor(blockSelector, closeHandler = false) {
		this.blockSelector = blockSelector;
		this.closeHandler = closeHandler;
		this.$block = $(blockSelector);
		this.message = new FancyBoxMessage();
	}

	addHandler() {
		if (this.$block && this.$block.length) {
			const $unitInput = this.$block.find('*[name="_wpcf7_unit_tag"]');
			if ($unitInput && $unitInput.length) {
				const unitTag = $unitInput.val();
				const $wpcf7Elm = $(`#${unitTag}`);
				if ($wpcf7Elm && $wpcf7Elm.length) {
					$wpcf7Elm.on("wpcf7:invalid", (event, detail) => {
						this.message.showMessage(detail.apiResponse.message, "danger");
					});
					/*        $wpcf7Elm.on('wpcf7:submit', function (event, detail) {
                        this.message.showMessage(detail.apiResponse.message);
                    }); */
					$wpcf7Elm.on("wpcf7:mailfailed", (event, detail) => {
						this.message.showMessage(detail.apiResponse.message, "danger");
					});
					$wpcf7Elm.on("wpcf7:mailsent", (event, detail) => {
						this.message.showMessage(
							detail.apiResponse.message,
							"success",
							this.closeHandler
						);
						$(".js--load_file_label").removeClass("uploaded");
					});
				}
			}
		}
	}
}

export default ContactForm;
